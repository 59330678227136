
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.primary-btn{
  background: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  font-family: 'ProximaNova Medium';
  font-weight: 300;
  color: var(--color-white);
  border-radius: 8px;
  box-shadow: none;
  padding: 0.5625rem 2rem 0.5rem;
  position: relative;
  font-size: 1rem;
  line-height: 21px;
  text-transform: capitalize;
  text-decoration: none;
  height: auto;
  margin: 0 15px 15px 0;
  box-sizing: border-box;
  border-width: 2px;
  cursor: pointer;
}

.primary-btn:focus {
  background: #292938;
  border-color: #292938;
  color: #fff;
  border-width: 2px;
}
.primary-btn:focus-visible {
  outline: none;
}
.primary-btn:hover{
  background: #292938;
  border-color: #292938;
  color: var(--color-white);
  border-width: 2px;
  box-shadow: 3px 3px 10px #c4c4ca;
}
button.primary-btn:disabled,
button.primary-btn[disabled]{
  box-shadow: none ;
  background: #c4c4ca ;
  color: #fff ;
  border: none ;
  opacity: inherit;
}
.secondary-btn {
  background: #fff;
  border: 2px solid #f36c21;
  color: #f36c21;
  box-shadow: none;
  font-family: 'ProximaNova Medium';
  font-weight: 300;
  border-radius: 8px;
  padding: 0.5625rem 2rem 0.5rem;
  position: relative;
  font-size: 1rem;
  line-height: 21px;
  text-transform: capitalize;
  text-decoration: none;
  height: auto;
  margin-bottom: 15px;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.secondary-btn:hover {
  background: #fee9d2;
  color: #f36c21;
  border-color: #f36c21;
  box-shadow: none;
  text-decoration: none;
  border-width: 2px;
}
.secondary-btn:focus-visible {
  outline: none;
}
.secondary-btn:focus {
  background: #f36c21;
  color: #fff;
}
@font-face {
  font-family: 'ProximaNova Bold';
  src: url('fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Black';
  src: url('fonts/proximanova-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Medium';
  src: url('fonts/proximanova-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Medium Italic';
  src: url('fonts/proximanova-mediumit-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/*Boost Next Font*/
@font-face {
  font-family: 'BoostNext Heavy';
  src: url('fonts/boost-next-heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BoostNext Regular';
  src: url('fonts/boost-next-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dish-icon';
  src:  url('dish-icons/fonts/icomoon.eot?fa45by');
  src:  url('dish-icons/fonts/icomoon.eot?fa45by#iefix') format('embedded-opentype'),
  url('dish-icons/fonts/icomoon.ttf?fa45by') format('truetype'),
  url('dish-icons/fonts/icomoon.woff?fa45by') format('woff'),
  url('dish-icons/fonts/icomoon.svg?fa45by#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/*Open Sans Font*/
@font-face {
  font-family: 'Open Sans';
  src: url('fonts/opensans-400.woff2') format('woff');
  font-weight: 400;
  font-style: normal
}

:root {
  --copyright-bg-color: #6e716e;
  --color-orange: #f36c21;
  --color-red11: #ea4335;
  --color-secondary: #171725;
  --color-very-dark-gray: #525259;
  --color-disable-btn-bg: #e0e0e0;
  --gray-1-new: #6B737A;
  --color-orange-light-new: #fe6100;
  --color-blue-link2: #0165bc;
  --color-subtext: #212121;
  --color-orange2-v2:#F68F1E;
  --gray-placeholder:  #757575;
  --color-red-required: #931621;
  --color-red-error: #f8d5d9;
  --color-light-green2: #e1f6e7;
  --color-bg-success :#38C05D;
  --color-orange-lite: #fcd3a5;
  --color-disable-btn-font: #bdbdbd;
  --color-white-smoke: #f2f2f2;
  --color-white-smoke-1: #F5F5F6;
  --color-brown-1: #555;
  --color-gray98: #fafafa ;
  --color-gray-dark: #c4c4ca;
  --color-tertiary: #292983;
  --color-grey-light-new1: #f4f4f4;
  --breadcrumbs-link: #525261;
  --color-gray_light: #c4c4c7;
  --color-white: #fff;
  --color-brown-2: #666666;
  --color-border-1: #E9EBED;
  --color-orange-button: #f25d12;
}
body .field [type="checkbox"]:checked + label,
body .field [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px ;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: var(--color-secondary);
}
body .field [type="checkbox"]:not(:checked) + label:before {
  content: '\e907';
  font-family: 'dish-icon';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.25rem;
}

body .field [type="checkbox"]:checked + label:before {
  content: '\e92d';
  font-family: 'dish-icon';
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.25rem;
  color: var(--color-orange);
}
body .field [type="checkbox"]:checked,
body .field [type="checkbox"]:not(:checked) {
  position: absolute;
  visibility: hidden;
}
body button.terms-label:after {
  content: '*';
  color: var(--color-red11);
  font-size: 1.2rem;
  margin: 0px 0px 0px 5px;
  display: inline-block;
}
.terms-label ul{
  padding-left:16px;
}
.is-danger{
  color: var(--color-red11);
  font-size: 0.875rem;
  font-family: 'ProximaNova Medium';
  margin-top: 5px;
}
.mt-10 {
  display: block;
  margin-top: 10px;
}
.mt-20 {
  display: block;
  margin-top: 20px;
}
.mb-10 {
  display: block;
  margin-bottom: 10px;
}
.mb-20 {
  display: block;
  margin-bottom: 20px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-0 {
  margin-top: 0;
}

.terms-label.action{
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1rem;
  font-family: 'ProximaNova Medium';
  color: #212121;
  font-weight: bold;
}
.tc-required {
  color: var(--color-secondary);
  font-size: 1rem;
  font-family: 'ProximaNova Medium';
  margin-bottom: 40px;
}
* {
  box-sizing: border-box;
}

.App {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 20px;
  padding-right: 20px;
}

fieldset {
  border:none;
  padding: 0;
}
fieldset legend {
  font-size: 1.5rem;
  color: var(--color-secondary);
  font-weight: 700;
  line-height: 1.75rem;
  font-family: 'BoostNext Heavy';
  border: none;
  margin-bottom: 20px;
}
.page-title {
  margin: 0 auto;
  width: 90%;
  font-weight: 300;
  color: var(--color-secondary);
  font-family: 'BoostNext Heavy';
  font-size: 3.25rem;
  margin-bottom: 25px;
}
fieldset .input {
  height: 57px;
  border-radius: 8px;
  border: 1px solid var( --gray-1-new);
  box-shadow: none;
  font-size: 1rem;
  width: 100%;
  font-family: 'Open Sans';
  padding: 10px 16px 0 16px;
}
fieldset .input:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--color-orange-light-new);
}

fieldset > .field {
  margin: 0 0 25px;
  padding: 4px 0 0;
  position: relative;
  border-radius: 5px 5px 0 0;
}
fieldset .label {
  color: var(--gray-placeholder);
  position: absolute;
  font-size: 1rem;
  top: 20px;
  z-index: 9;
  padding-left: 16px;
  margin: 0 0 5px;
  font-family: 'ProximaNova Medium';
}

fieldset select {
  height: 57px;
  border: 1px solid var(--gray-1-new);
  border-radius: 8px;
  color: var(--color-white);
  font-size: 1rem;
  transition: none;
  font-weight: normal;
  width: 100%;
  padding: 0 10px 0 14px;
  background: var(--color-white) url(../public/select-bg.svg) no-repeat 100% 45% !important;
  -webkit-appearance: none;
  padding-top: 14px;
}
fieldset > .field.required > .label:after {
  content: '*';
  color: var(--color-red-required);
  font-size: 1.2rem;
  margin: 0 0 0 5px;
}
fieldset select:focus{
  color:#212121;
  outline: none;
}

.form-field--is-filled > .label , .form-field--is-active > .label{
  color: var(--gray-placeholder);
  position: absolute;
  font-size: 0.75rem;
  top: 6px;
}
.form-field--is-filled select,.form-field--is-active select{
color: var(--color-subtext);
}

.tc-required label[for="unavailable-limited"] , .tc-required label[for="unavailable-limited"] ul {
  margin: 0;
}
.tc-required ul > li , .react-responsive-modal-modal ul > li{
  margin-bottom: 5px;
}
.field [type="checkbox"] {
  top: 20px;
}

.react-responsive-modal-modal h3 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  color: var(--color-secondary);
  font-family: 'BoostNext Heavy';
  padding-top: 2.8rem;
}
.react-responsive-modal-modal {
  font-size: 1rem;
  font-family: 'ProximaNova Medium';
  line-height: normal;
  color: var(--color-very-dark-gray);
}
.react-responsive-modal-modal .msg {
  font-size: 1.25rem;
  font-family: 'BoostNext Heavy';
  color: #171725;
  margin: 0 0 25px;
  display: block;
}
.interuppted-msg h1{
  font-weight: 300;
  margin: 0 0 20px;
  color: #171725;
  font-family: 'BoostNext Heavy';
  font-size:3.25rem;
}
.interuppted-msg p {

  font-size: 1rem;
  font-family: 'ProximaNova Medium';
  line-height: normal;
  color: #525259;
  margin: 0;
}
.react-responsive-modal-modal p {
  margin-top: 0rem;
  margin-bottom: 1rem;
}
.react-responsive-modal-modal strong {
  font-weight: 700;
}
.react-responsive-modal-modal a {
  color: var(--color-secondary);
  font-weight: 500;
  text-decoration: none;
}

.react-responsive-modal-modal a:hover{
color: #0165bc;
text-decoration: underline;
}
.react-responsive-modal-modal p {
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.modal-wrapper {
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 2.6rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.react-responsive-modal-modal .is-info.primary-btn {
  width: 90px;
  display: flex;
  margin: 3rem 3rem 3rem auto;
}


.react-responsive-modal-closeButton {
  padding: 1.1rem 2rem !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  font-weight: 400;
  display: inline-block;
}
input.is-danger {
  color: #212121; 
  border: 1px solid #ea4335;
}
.updateaddress-modal a.primary-btn {
  color: #fff;
  font-weight: 300;
  padding: 0.6rem 2rem 0.6rem;
  text-decoration: none;
}
body .updateaddress-modal .react-responsive-modal-closeButton {
  display:none;
}
.react-responsive-modal-modal.updateaddress-modal {
  max-width: 40% !important;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 0 12px #c4c4ca;
  background: #ffffff;
  text-align: center;
  overflow-y: auto !important;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  right: auto !important;
  position: absolute !important;
  height: auto;
  width: 100%;
  margin: 0 !important;
}
.react-responsive-modal-modal.updateaddress-modal .modal-wrapper{
  padding: 20px;
} 
.react-responsive-modal-modal.updateaddress-modal  h3 {
  padding-top: 0;
}

/*Suggested Address modal Start*/
.updateaddress-modal.suggested-address-modal{
  max-width:465px !important;
}
.updateaddress-modal.suggested-address-modal h3 {
  font-size: 1.5rem;
  font-family: 'BoostNext Heavy';
  margin-bottom: 24px;
}
.updateaddress-modal.suggested-address-modal .msg {
  margin: 0 0 16px;
  color: #525261;
  text-align: left;
  font-family: 'ProximaNova Medium';
  font-size: 1rem;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;
}
.updateaddress-modal.suggested-address-modal img{
  display: block;
  margin: 20px auto;
}
.updateaddress-modal.suggested-address-modal strong {
  display: block;
  margin-bottom: 24px;
}
.updateaddress-modal.suggested-address-modal .modal-wrapper {
  padding: 20px 30px;
}
body .updateaddress-modal.suggested-address-modal .primary-btn,
body .updateaddress-modal.suggested-address-modal .secondary-btn{
  width: 100%;
  padding: 12px;
}
body .updateaddress-modal.suggested-address-modal .react-responsive-modal-closeButton {
  display: block;
  fill: #8f8f8f;
}
body .updateaddress-modal.suggested-address-modal .react-responsive-modal-closeButton:hover {
  fill: #000;
}

/*Suggested Address modal End*/

.message {
  margin: 0 0 18px;
  padding: 12px 20px 12px 25px;
  display: block;
  font-size: 1.3rem;
  padding-left: 45px;
  font-family: 'ProximaNova Medium';
  position: relative;
}

.message.success {  
 background: #e1f6e7;
  color: #38c05d; 
}

.message.error {
  background: #f8d5d9;
  color: #ea4335;
}
.message:before {
  font-size: 1.25rem;
  line-height: 28px;
  font-family: 'dish-icon';
  margin: -12px 0 0;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  left: 0;
  top: 22px;
  width: 45px;
  position: absolute;
  text-align: center;
}
.message.error:before {
  color: #ea4335;
  content: '\e919';
}
.message.success:before {
  content: '\e91c';
  font-family: 'dish-icon';
  font-size: 0.75rem;
  font-weight: 200;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #38c05d;
  color: #38c05d;
  line-height: 20px;
  margin: -8px 12px;
}
.align-btn .arrow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.align-btn .arrow img{
  margin-left: 11px;
  width: 22px;
}
/* loader start */
.backdrop {
  background: rgba(255, 255, 255, 0.5) !important;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  display: inline-block;
  z-index: 10;
}
body .loading-container.animation-2 {
  animation: rotation 1s infinite;
  animation: rotation 2s infinite;
    
  transform: rotate(10deg);
}
body .loading-container.animation-2 .shape {
  border-radius: 5px;
}
body .loading-container.animation-2.top-position-30 {
  position: absolute;
  top: 30%;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
  top: 0;
}
body .loading-container {
  width: 30px;
  height: 30px;
  position: relative;
  margin: 20% auto 0;
  z-index: 9999;
}
body .loading-container .shape.shape1 {
  left: 0;
  background-color: orange;
}
body .loading-container .shape {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 1px;
}
body .loading-container .shape.shape2 {
  right: 0;
  background-color: orange;
}
body .loading-container .shape.shape3 {
  bottom: 0;
  background-color: orange;
}
body .loading-container .shape.shape4 {
  bottom: 0;
  right: 0;
  background-color: orange;
}
body .animation-2 .shape1 {
  -webkit-animation: animation2shape1 .5s ease 0s infinite alternate;
  animation: animation2shape1 .5s ease 0s infinite alternate;
}
body .animation-2 .shape2 {
  -webkit-animation: animation2shape2 .5s ease 0s infinite alternate;
  animation: animation2shape2 .5s ease 0s infinite alternate;
}
body .animation-2 .shape3 {
  -webkit-animation: animation2shape3 .5s ease 0s infinite alternate;
  animation: animation2shape3 .5s ease 0s infinite alternate;
}
body .animation-2 .shape4 {
  -webkit-animation: animation2shape4 .5s ease 0s infinite alternate;
  animation: animation2shape4 .5s ease 0s infinite alternate;
}
@keyframes animation2shape1 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(20px, 20px);
    transform: translate(20px, 20px);
  }
}
@keyframes animation2shape2 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-20px, 20px);
    transform: translate(-20px, 20px);
  }
}
@keyframes animation2shape3 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(20px, -20px);
    transform: translate(20px, -20px);
  }
}
@keyframes animation2shape4 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-20px, -20px);
    transform: translate(-20px, -20px);
  }
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* loader end */
@media screen and (min-width: 768px) {
  fieldset legend {
    margin-left:25%;
  }
  .react-responsive-modal-modal {
    width: 100vw;
    max-width: 80vw !important;
    max-height: calc(100vh - 10rem);
    transform: translateY(0);
    margin: 5rem auto !important;
    display: flex !important;
    flex-direction: column;
    height: auto;
    position: absolute !important;
    right: 0 !important;
    left: 0 !important;
    transition: transform .2s ease;
    background-color: var(--color-white);
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    opacity: 1;
    pointer-events: auto;
    outline: none;
    padding: 0 !important;
  }
  .align-btn{
    display: flex;
  }
}
@media screen and (max-width: 1024px) {
  .react-responsive-modal-modal.updateaddress-modal {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 767px) {
  .suggested-address-modal .react-responsive-modal-closeButton {
    padding: 0.8rem 1.2rem !important;
  }
  .react-responsive-modal-modal.suggested-address-modal {
    max-width: 90% !important;
}
  .page-title {
    text-align: center;
    font-size: 2.25rem;
  }
  .is-block.is-info.primary-btn {
    width: 100%;
    margin-right: 0;
    font-size: .875rem;
  }
  .tc-required p ,  fieldset label , .terms-label.action , .react-responsive-modal-modal , .react-responsive-modal-modal p{
    font-size: .875rem;
    line-height: 20px;
  }
  .App {
    padding-left: 15px;
    padding-right: 15px;
  }
  .react-responsive-modal-container {
    height: auto !important;
}
  .react-responsive-modal-modal {
    background-color: var(--color-grey-light-new1) !important;
    transform: translateX(0) !important;
    margin: 0 !important;
    max-height: 100vh !important;
    transition: transform 0.3s ease-in-out;
    width: auto;
    height: 100%;
    box-shadow: 0 0 12px 2px rgb(0 0 0 / 35%);
    opacity: 1;
    pointer-events: auto;
    padding: 0 !important;
    display: block !important;
    outline: none;
  }
  .react-responsive-modal-modal .is-info.primary-btn {
    width: 90px;
    margin:1rem 3rem 3rem;
  }
  .modal-wrapper {
    border: none;
    padding-bottom: 0;
  }
  .react-responsive-modal-closeButton {
    padding: .5rem 1rem;
  }
  .react-responsive-modal-modal.updateaddress-modal .primary-btn {
    margin: 0 auto 15px auto;
    display: block;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
